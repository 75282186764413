<template>
  <div id="imgBox">
    <img src="../assets/img/1.1(1).jpg" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
#imgBox {
  width: 80%;
  height: 900px;
  margin-top: -30px;
  margin: auto;
}
img {
  width: 100%;
  height: 80%;
  margin: auto;
}
</style>
